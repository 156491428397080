@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title{
  font-feature-settings: 'clig' off, 'liga' off;

  /* Display */
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px; /* 111.111% */
  letter-spacing: -2.4px;
 
}

.title-secondary{
  font-feature-settings: 'clig' off, 'liga' off;
  
  /* H/3 */
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 118.75% */
  letter-spacing: -1px;
}

.text{
  font-feature-settings: 'clig' off, 'liga' off;

  /* Text/18 */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.2px;
}

.title-tag{
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  
  /* H/5 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.6px;
}

.button-full{
  display: flex;
  width: 209px;
  height: 48px;
  padding: 12px 21px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #DF1515;
  color: white;
}

.button-outline{
  display: flex;
  width: 209px;
  height: 48px;
  padding: 12px 21px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #DF1515;
  color: #DF1515;
} 


@media only screen and (max-width: 600px) {
    .title{
      font-size: 45px;
      font-weight: 700;
      line-height: 50px; /* 111.111% */
      letter-spacing: -1px;
    
    }
  }